import logo from './logo.svg';
import './App.css';
import ProgressBar from "@ramonak/react-progress-bar";
import TagsCloud from "./components/TagsCloud/TagsCloud";
import ExternalLink from "./components/ExternalLink/ExternalLink";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <content>
        <h1>План обучения Frontend</h1>
        <p>Изложенная информация, очевидно, не избыточная. Тот же самый HTTP предполагает значительно больший объем информации для полноценного понимания всех нюансов.</p>
        <p>Предложенный план покрывает минимально нужный объем информации, чтобы начать работать на практике с базовым пониманием как устроены вещи, как следствие — делать это осмысленно.</p>
        <h2>Оглавление</h2>
      
        <TagsCloud/>

        <h2 id="network_basic">Сетевые основы</h2>
        <p>Здесь формируется базовое понимание о том, как работает интернет в принципе, как интернет-ресурсы общаются между собой и устройство запросов. </p>
        <p>Кроме этого, на информации из этого раздела строится понимание работы RESTful API.</p>
        <ProgressBar 
          completed={Math.round(14/14*100)} 
        />
        <ol>
          <li class="done">Модель OSI:</li>
          <ul>
            <li class="done">
              <ExternalLink 
                href="https://habr.com/ru/company/serverspace/blog/689704/" 
                label="Начиная с «Сетевая модель OSI – «это баааза»"/>.
            </li>
            <li class="done">
              <ExternalLink 
                  href="https://selectel.ru/blog/osi-for-beginners/" 
                  label="Простое пособие по сетевой модели OSI для начинающих"/>.
            </li>
          </ul>
          <li class="done">Что такое интернет-сокеты (<ExternalLink href="https://thecode.media/socket/" label="Что такое сокет и зачем он нужен"/>).</li>
          <li class="done">Протоколы TCP, UDP (<ExternalLink href="https://www.cloud4y.ru/blog/tcp-vs-udp/" label="TCP и UDP: в чём отличия протоколов"/>).</li>
          <li class="done">Сетевая модель TCP/IP:</li>
          <ul>
            <li class="done">
              Попроще — <ExternalLink 
                href="https://timeweb.com/ru/community/articles/chto-takoe-tcp-ip" 
                label="Что такое TCP/IP и как работает этот протокол"/>.
            </li>
            <li class="done">
              Для закрепления — <ExternalLink 
                  href="https://ru.m.wikipedia.org/wiki/TCP/IP" 
                  label="TCP/IP"/>.
            </li>
          </ul>
          <li class="done">Разница между OSI и TCP/IP (<ExternalLink href="https://alishoff.com/blog/363" label="OSI vs. TCP/IP"/>).</li>
          <li class="done">ARP — Address Resolution Protocol (<ExternalLink href="https://zvondozvon.ru/tehnologii/protokoli/arp" label="Протокол ARP — протокол разрешения адресов, принцип работы"/>).</li>
          <li class="done">Стандарт и технология Ethernet (<ExternalLink href="https://andreyex.ru/seti/kak-rabotaet-ethernet/" label="Как работает Ethernet"/>).</li>
          <li class="done">IP-адреса и подсети (<ExternalLink href="https://itandlife.ru/technology/computer-networks/ip-adresaciya-klassy-ip-adresov-i-znachenie-maski-podseti/" label="IP адресация, классы IP адресов и значение маски подсети"/>).</li>
          <li class="done">Протокол HTTP (<ExternalLink href="https://developer.mozilla.org/ru/docs/Web/HTTP/Overview" label="Обзор протокола HTTP"/>).</li>
          <li class="done">Отдельно про методы POST, GET, PUT, PATCH, DELETE (<ExternalLink href="https://devanych.ru/technologies/metody-http-zaprosa" label="Методы HTTP запроса"/>).</li>
          <li class="done">Отдельно про HEAD запросы (<ExternalLink href="https://developer.mozilla.org/ru/docs/Web/HTTP/Methods/HEAD" label="HEAD"/>).</li>
          <li class="done">Веб-заголовки, они же HTTP-Headers (<ExternalLink href="https://code.tutsplus.com/ru/tutorials/http-headers-for-dummies--net-8039" label="HTTP Headers  для 'чайников'"/>).</li>
        </ol>

        <h2 id="web_basics">Базовые технологии веба</h2>
        <p>Базовые технологии разработки веб-страниц. После сетевых основ это первое что следует изучить, чтобы остальное было понятнее. </p> 
        <p>Вопрос HTML как такового не поднимается, предполагается, что его знание уже есть в априорном виде.</p>
        <ProgressBar 
          completed={Math.round(5/5*100)} 
        />   
        <ol start="13">
          <li class="done">DOM — cтруктура страницы и обработка HTML:</li>
          <ul>
            <li class="done">
              <ExternalLink 
                href="https://itchief.ru/javascript/dom" 
                label="Что такое DOM и зачем он нужен?"/>.
            </li>
            <li class="done">
              <ExternalLink 
                href="https://itchief.ru/javascript/dom-nodes" 
                label="Узлы и элементы DOM-дерева в JavaScript"/>.
            </li>
          </ul>
          <li class="done">Хранение данных методами браузера:</li>
          <ul>
            <li class="done">Cookies (<ExternalLink href="https://developer.mozilla.org/ru/docs/Web/HTTP/Cookies" label="HTTP-куки"/>).</li>
            <li class="done">WebStorage API (<ExternalLink href="https://habr.com/ru/post/496348/" label="Web Storage API: примеры использования"/>).</li>
            <li class="done">IndexedDB API (<ExternalLink href="https://learn.javascript.ru/indexeddb" label="IndexedDB"/>).</li>
          </ul>
        </ol>  

        <h2 id="best_practices">Общие принципы разработки</h2>
        <p>Концептуальные подходы к тому, как делать правильно. Перед разработкой нужно сначала понять концепции, чтобы понимание последующих знаний определялось хорошими практиками, а не наитием.</p>
        <ProgressBar 
          completed={Math.round(0/3*100)} 
        />     

        <ol start="15">
          <li>Компонентный подход к веб-разработке (<ExternalLink href="https://ru.bem.info/methodology/quick-start/" label="Быстрый старт"/>).</li>
          <li>Принципы SOLID, KISS, DRY, YAGNI и прочие:</li>
            <ul>
              <li>
                В общих чертах — <ExternalLink 
                  href="https://habr.com/ru/company/itelma/blog/546372/" 
                  label="Принципы для разработки: KISS, DRY, YAGNI, BDUF, SOLID, APO и бритва Оккама"/>.
              </li>
              <li>
                <ExternalLink 
                  href="https://habr.com/ru/post/714068/" 
                  label="Принципы SOLID на JS, теперь точно простым языком, но не очень коротко"/>.
              </li>
            </ul>
        </ol> 

        <h2 id="css">CSS и верстка</h2>
        <p>Вопросы качественной, красивой и переиспользуемой верстки.</p>
        <ProgressBar 
          completed={Math.round(1/8*100)} 
        />     
        <ol start="17">
          <li>CSS в принципе — основные методы, конструкции и так далее.</li>
          <li>CSS по БЭМ (<ExternalLink href="https://ru.bem.info/methodology/css/" label="CSS / Методология / БЭМ (bem.info)"/>).</li>
          <li>Псевдоэлементы в CSS.</li>
          <li>Flex в CSS.</li>
          <li>CSS пре и пост процессоры:</li>
          <ul>
            <li class="done">
              Что и зачем это нужно — <ExternalLink 
                href="https://habr.com/ru/post/434098/" 
                label="Post & Pre Processing CSS / Хабр (habr.com)"/>.
            </li>
            <li>Частная и популярная реализация — SASS</li>
          </ul>
          <li>Вспомогательные инструменты:</li>
          <ul>
            <li>Pixel Perfect.</li>
            <li>Zeplin, Figma. Инструменты экспорта макетов в HTML.</li>
          </ul>
        </ol>

        <h2 id="api">API</h2>
        <p>Здесь разбирается предмет самой востребованной на данный момент архитектуры проектирования API — REST API.</p>
        <p>Информация здесь базируется на прошлом разделе и предполагается, что базовая концепция (что такое API, зачем нужно) и так ясна.</p>
        <ProgressBar 
          completed={Math.round(3/9*100)} 
        />     
        <ol start="23">
          <li class="done">Почитать за Rest API.</li>
          <ul>
            <li class="done">
              <ExternalLink 
                href="https://habr.com/en/post/483202/" 
                label="Введение в REST API"/>.
            </li>
            <li class="done">
              <ExternalLink 
                href="https://habr.com/ru/post/447322/" 
                label="Принципы построения REST JSON API"/> , но только вместе с комментами — не все там однозначно!
            </li>
            <li>
              Пример — <ExternalLink 
                href="https://docs.retailcrm.ru/Developers/API/APIVersions/APIv5#get--api-credentials" 
                label="docs.retailcrm.ru"/>.
            </li>
          </ul>
          <p> 
          </p>
          <li class="done">Почитать за JSON web tokens (<ExternalLink href="https://habr.com/ru/post/340146/" label="Пять простых шагов для понимания JSON Web Tokens (JWT)"/>).</li>
          <li class="done">Почитать за Refresh Token (<ExternalLink href="https://habr.com/ru/post/466929/" label="Добавляем Refresh Token"/>).</li>
          <li>Как работает публичный и приватный ключ. Асинхронное шифрование.</li>
          <li>Почитать за JSON RPC (<ExternalLink href="https://habr.com/ru/post/441854/" label="REST? Возьмите тупой JSON-RPC"/>).</li>
          <li>Почитать WSDL.</li>
          <li>Почитать SWAGGER.</li>
          <li>Почитать Open API.</li>
        </ol>

        <h2 id="tools">Инструменты</h2>
        <p>Перед тем как перейти непосредственно к JS, следует должным образом рассмотреть некоторые базовые инструменты и понятия, которые помогают при работе с фреймворками и в принципе.</p>
        <ProgressBar 
          completed={Math.round(3/10*100)} 
        />  
        <ol start="31">
          <li>Вспомнить что такое фреймворк и чем он отличается от библиотеки.</li>
          <li>Почитать за Webpack и прочие сборщики.</li>
          <li>Почитать за GIT и его методы:</li>
          <ul>
          <li class="done">База — <ExternalLink 
                  href="https://habr.com/ru/post/541258/" 
                  label="Git для новичков (часть 1)"/>.
            </li>
            <li>Совместная работа — <ExternalLink 
                  href="https://habr.com/ru/post/542616/" 
                  label="Git для новичков (часть 2)"/>.
            </li>
            <li>Внесение собственного вклада в проекты — <ExternalLink 
                  href="https://git-scm.com/book/ru/v2/GitHub-%D0%92%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B5-%D1%81%D0%BE%D0%B1%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B2%D0%BA%D0%BB%D0%B0%D0%B4%D0%B0-%D0%B2-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D1%8B" 
                  label="GitHub - Внесение собственного вклада в проекты"/>.
            </li>
            <li class="done">
              <ExternalLink 
                    href="https://habr.com/ru/company/vk/blog/493816/" 
                    label="Руководство по Git. Часть №1: все, что нужно знать про каталог .git"/>.
            </li>
            <li>
              <ExternalLink 
                    href="https://git-scm.com/book/ru/v2/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-C%3A-%D0%9A%D0%BE%D0%BC%D0%B0%D0%BD%D0%B4%D1%8B-Git-%D0%9D%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%B8-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B3%D1%83%D1%80%D0%B0%D1%86%D0%B8%D1%8F" 
                    label="Команды Git"/>.
            </li>
          </ul>
          <li>Освежить знания по Docker.</li>
          <li class="done">Узнать что такое RabbitMQ и зачем он нужен.</li>
        </ol>

        <h2 id="js">Javascript</h2>
        <p>База по интерактивной разработке в вебе.</p>
        <ProgressBar 
          completed={Math.round(0/7*100)} 
        />  
        <ol start="36">
          <li>Прочитать учебник по JS.</li>
          <li>Почитать за DOM.</li>
          <li>Распределение памяти, парсинг кода, выполнение, разница между JavaScript Engine и JavaScript Run-time Enviroment (<ExternalLink href="https://proweb63.ru/help/js/js-async-await" label="Понимание синхронного и асинхронного JavaScript с Async/Await"/>).</li>
          <li>Освежить знания ReactJS (<ExternalLink href="https://reactjs.org" label="Reactjs"/>).</li>
          <li>Почитать за Material UI (<ExternalLink href="https://habr.com/ru/post/492378/" label="Reactjs, Material-UI with JSS. Краткий гайд / Хабр (habr.com)"/>).</li>
          <li>Почитать за Next.js.</li>
          <li>Базово почитать за TypeScript.</li>
        </ol>

        <h2 id="test">Теория тестирования</h2>
        <p>Тестирование не очень часто встречается в начале работы Frontend, но понимать что это и зачем надо.</p>
        <ProgressBar 
          completed={Math.round(0/1*100)} 
        />
        <ol start="43">
          <li>Почитать за unit-тесты.</li>
        </ol>

        <h2 id="practice">Практика</h2>
        <p>Если на каком-то этапе практики произошел сбой, и оказалось, что теоретических знаний из разделов ранее недостаточно, следует дополнить соответствующие разделы.</p>
        <p>А так практика она и есть практика.</p>
        <ProgressBar 
          completed={Math.round(0/9*100)} 
        />
        <ol start="44">
          <li>Поэкспериментировать с запросами через POSTMAN, например:</li>
          <ul>
            <li>что возвращают.</li>
            <li>в каком формате.</li>
            <li>где какие данные есть.</li>
          </ul>
          <li>Написать что-то на реакте с использованием:</li>
          <ul>
            <li>socket’ов.</li>
            <li>SASS.</li>
            <li>SSR.</li>
            <li>unit-тестов.</li>
            <li>material UI.</li>
          </ul>
          <li>Воскресить <ExternalLink href="https://github.com/treepoint/todayTasksFront" label="TodayTasks"/> в минимальной комплектации (ReactJS + Node).</li>
        </ol>

        <h2 id="additional">Дополнительно</h2>
        <p>Интересные темы, которые с высоким ожиданием не будут востребованы на первых порах.</p>
        <p>Тем не менее, для общего развития можно и посмотреть.</p>
        <ProgressBar 
          completed={Math.round(3/8*100)} 
        />
        <ol start="47">
          <li class="done">Почитать за API-контракты.</li>
          <li>GIT flow (<ExternalLink href="https://www.atlassian.com/ru/git/tutorials/comparing-workflows/gitflow-workflow#:~:text=Git%2Dflow%20%E2%80%94%20%D0%B0%D0%BB%D1%8C%D1%82%D0%B5%D1%80%D0%BD%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%B0%D1%8F%20%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D1%8C%20%D0%B2%D0%B5%D1%82%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F,%D0%92%D0%B8%D0%BD%D1%81%D0%B5%D0%BD%D1%82%D0%BE%D0%BC%20%D0%94%D1%80%D0%B8%D1%81%D1%81%D0%B5%D0%BD%D0%BE%D0%BC%20%D0%BD%D0%B0%20%D1%81%D0%B0%D0%B9%D1%82%D0%B5%20nvie." label="Рабочий процесс Gitflow Workflow | Atlassian Git Tutorial" />).</li>
          <li>Базово почитать за сортировку пузырьком и алгоритмы сортировки в целом (<ExternalLink href="https://academy.yandex.ru/journal/osnovnye-vidy-sortirovok-i-primery-ikh-realizatsii" label="Основные виды сортировок"/>).</li>
          <li>Посмотреть что такое вообще Nuxt.</li>
          <li>Посмотреть что такое вообще Tailwind.</li>
          <li class="done">Почитать за SOCKS. Ни разу не требовалось пока, но вдруг (<ExternalLink href="https://ru.m.wikipedia.org/wiki/SOCKS" label="Вики, SOCKS"/>).</li>
          <li class="done">Почитать за cookies-зомби (<ExternalLink href="https://www.computerra.ru/180843/zombie-cookies/" label="Зомби-куки: было бы смешно, если б не было грустно"/>), это практически бесполезно, но увлекательно!</li>
        </ol>

        <h2 id="selfcheck">Самопроверка</h2>
        <p>Тестирование и подобное для проверки усвоения материала.</p>
        <p>В идеале должно быть для каждого раздела.</p>
        <ProgressBar 
          completed={Math.round(0/1*100)} 
        />
        <ol start="54">
          <li> <ExternalLink 
                    href="https://xn-----xlcfvffioc4g.xn--p1ai/tests/network-technology-basics-quiz" 
                    label="Тестирование знаний по основам сетей"/>.</li>
        </ol>

        <h2 id="books">Литература</h2>
        <p>Монументальные труды по разработке. Ходят слухи, что если осилить все 4, то можно выйти на уровень Сайтамы, а то и выше.</p>
        <ProgressBar 
          completed={Math.round(0/4*100)} 
        />
        <ul>
          <li>Таненбаум — Компьютерные сети.</li>
          <li>Кнут — Искусство программирования.</li>
          <li>Таненбаум — Операционные системы.</li>
          <li>Настольная книга GIT — <ExternalLink href="https://git-scm.com/book/ru/v2" label="Book"/>.</li>
        </ul>
        <h2 id="ps">P.S.</h2>
        <p>Мое уважение, если добрались сюда. План прокомментирован в базовом виде и имеет свой домен, потому что кажется логичным, потом обернуть это в обучающий сайт-сервис.</p>
        <p>Сейчас это все просто статичная верстка, которую я периодически редактирую вручную. Но на деле никто не мешает потом прикрутить сюда систему учетных записей и давать пользователям самим отмечать выполненно и следить за своим прогрессом.</p>
        <p>Таким образом, это с одной стороны веселее чем просто учиться по списку — есть побочная цель — с другой стороны можно будет потом немного поSEOшить страницу и получать пару долларов с рекламы.</p>
      </content>
    </div>
  );
}

export default App;
