import React from 'react';
import Tag from './elements/Tag'
import './style.css';

class TagsCloud extends React.Component {
    getTags() {
        let tags = [
            {'name' : "Сетевые основы", 'href' : "#network_basic"},
            {'name' : "Базовые технологии веба", 'href' : "#web_basics"},
            {'name' : "Общие принципы разработки", 'href' : "#best_practices"},
            {'name' : "CSS и верстка", 'href' : "#css"},
            {'name' : "API", 'href' : "#api"},
            {'name' : "Инструменты", 'href' : "#tools"},
            {'name' : "Javascript", 'href' : "#js"},
            {'name' : "Теория тестирования", 'href' : "#test"},
            {'name' : "Практика", 'href' : "#practice"},
            {'name' : "Дополнительно", 'href' : "#additional"},
            {'name' : "Самопроверка", 'href' : "#selfcheck"},
            {'name' : "Литература", 'href' : "#books"}
        ];
    
        let cloud_tags = tags.map((tag) =>
            <Tag href={ tag['href'] } name={ tag['name'] }/>
        );
    
        return cloud_tags;
    }

    render() 
    {
      return (
        <div class="tags-cloud">
            {this.getTags()}
        </div>);
    }
}

export default TagsCloud;