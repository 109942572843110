import React from 'react';

class ExternalLink extends React.Component {
    render() 
    {
      return (
            <a 
                target="_blank" 
                rel="noreferrer" 
                href={this.props.href}>
                    { this.props.label }
            </a>
        )
    }
}

export default ExternalLink;