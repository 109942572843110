import React from 'react';
import './style.css';

class Tags extends React.Component {
    render() 
    {
      return (
            <button 
                class="tag" 
                onClick={() => {window.location.href = this.props.href }}
            >
                { this.props.name }
            </button>
        )
    }
}

export default Tags;